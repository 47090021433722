
export default {
  props: ["communityID"],
  data: () => ({
    members: [],
    pendingMembers: [],
    displayType: "default",
    isLoading: true,
    allowedManagePendingRole: ["owner", "admin"],
    page: 1,
    intervalID: null,
  }),
  mounted() {
    this.isLoading = true;

    this.getAllPendingMembers();
    this.addPage();
    this.intervalID = setInterval(this.addPage, 3000);

    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  },
  methods: {
    async addPage() {
      const res = await this.$getSectionContent(
        "member",
        this.communityID,
        this.page,
        10,
        "&status=active"
      );

      this.members = [...this.members, ...res];

      this.page = this.page + 1;
      if (res.length < 10) {
        clearInterval(this.intervalID);
      }
    },

    async getAllMembers() {
      this.isLoading = true;
      const allMembers = await this.$getSectionContent(
        "member",
        this.communityID,
        1,
        10,
        "&status=active"
      );
      await this.getAllPendingMembers();

      this.members = await allMembers;
      this.isLoading = false;
    },
    async getAllPendingMembers() {
      const pendingMembers = await this.$getSectionContent(
        "member",
        this.communityID,
        1,
        100,
        "&status=pending"
      );

      this.pendingMembers = await pendingMembers;
    },
    handleUserAcceptance(type, id, username = "") {
      this.$setMemberStatus(id, type)
        .then((res) => {
          if (res.status) {
            this.$message({
              message: `${username} has been approved`,
              type: "success",
              onClose: () => window.location.reload(),
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "Failed approve member",
            type: "error",
          });
        });
    },
  },
};
