
export default {
  props: {
    discussionData: {
      default: {
        id: null,
        is_pinned: 0,
        description: "",
        created_at: "",
        description: "",
        total_comment: 0,
        community_content_media: [],
        total_like: 0,
        total_view: 0,
        listing: null,
        user: {
          avatar: "",
          username: "",
          level: "",
          id: null,
        },
      },
    },
  },
  data: () => ({
    link: "",
    allowedRole: ["owner", "admin", "moderator"],
  }),
  created() {
    const idComm = this.$route.params.id;
    this.link = "/mb/community/" + idComm + "/" + this.discussionData.id;
  },
};
