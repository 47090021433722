
export default {
  props: ["communityID"],
  data: () => ({
    medias: [],
    isLoading: true,
    isModalOpened: false,
    isDeleteModalOpened: false,
    page: 1,
    intervalID: null,
    selectedContentId: "",
    selectedListingId: "",
    discussionType: "",
    loading: {
      sold: false,
    },
  }),
  mounted() {
    this.isLoading = true;
    this.addPage();
    this.intervalID = setInterval(this.addPage, 3000);

    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
    this.$nuxt.$on("onClickSell", this.onClickSellHandler);
    this.$nuxt.$on("onClickDelete", this.onClickDelete);
  },
  methods: {
    async addPage() {
      const res = await this.$getSectionContent(
        "medias",
        this.communityID,
        this.page
      );

      this.medias = [...this.medias, ...res];

      this.page = this.page + 1;
      if (res.length < 10) {
        clearInterval(this.intervalID);
      }
    },
    onClickSellHandler(communityContentId, listingId) {
      this.isModalOpened = true;
      this.selectedContentId = communityContentId;
      this.selectedListingId = listingId;
    },
    async sellListingHandler() {
      this.loading.sold = true;
      try {
        const res = await this.$axios.post(
          `${process.env.BASE_API}communities/questions/${this.selectedContentId}`,
          {
            id_community: this.$route.params.id,
            is_sold: 1,
          }
        );
        this.medias = this.medias.map((media) => {
          if (media.id === this.selectedContentId) {
            return {
              ...media,
              listing: {
                ...media.listing,
                marketplace_item: {
                  ...media.listing.marketplace_item,
                  is_sold: 1,
                },
              },
            };
          } else return { ...media };
        });
        this.isModalOpened = false;
        this.$message({
          message: "Berhasil menjual barang",
          type: "success",
        });
      } catch (error) {
        this.$message({
          message: "Gagal update listing",
          type: "error",
        });
        throw error;
      } finally {
        this.loading.sold = false;
      }
    },
    onClickDelete(communityContentId, type) {
      this.selectedContentId = communityContentId;
      this.discussionType = type;
      this.isDeleteModalOpened = true;
    },
    deleteListing() {
      this.$editQuestion(this.selectedContentId, { is_active: 0 })
        .then((response) => {
          this.$notify.success({
            message: "Listing berhasil dihapus",
            offset: 100,
            duration: 3500,
            onClose: () => {
              window.location.reload();
            },
          });
        })
        .catch((err) => {
          this.$notify.error({
            message: "Listing gagal dihapus",
            offset: 100,
            duration: 3500,
          });
        })
        .finally(() => {
          this.isDeleteModalOpened = false;
        });
    },
    deleteDiscussion() {
      this.$deleteDiscussion(this.selectedContentId)
        .then((response) => {
          if (response.status) {
            this.$notify.success({
              message: `Berhasil menghapus diskusi`,
              offset: 100,
              duration: 1500,
              onClose: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `Gagal menghapus diskusi`,
            offset: 100,
            duration: 1500,
          });
        })
        .finally(() => (this.isDeleteModalOpened = false));
    },
  },
};
